import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
//import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { Observable } from 'rxjs';
//
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { SysService } from '@app/_common';
import { environment } from '@environments/environment';
import { HttpUrlEncodingCodec } from './http-url-encoding-codec';
import * as _ from 'lodash';
//
//import { UserService } from '@app/_common';
//import {AppConfig} from '@app/_config/';
//
//@Injectable({
//  providedIn: 'root'
//})
export class Model {

  formData: FormData | null;
  id: any;
  apiUrl: string; //Api url of the service
  form: FormGroup;
  list: any = [];
  view: any;
  selection: any = []; //filtered or selected list
  private params: HttpParams;
  total: number = 0;
  state: string = 'new';
  islist: boolean = true;    //list
  isform: boolean = false;   //new and edit
  isformview: boolean = false; //new, edit and view
  formDefault: FormDefault;


  constructor(form: FormGroup, public sysService: SysService, formDefault: FormDefault, apiUrl: string = '') {
    // this.sysService.clear(); //to reset the validation error
    this.form = form;
    this.formDefault = formDefault;
    this.apiUrl = (apiUrl.length === 0) ? environment.appApi : apiUrl;
  }


  get p() {
    if (!this.params) {
      this.params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    }
    return this.params;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  param(key: any) {
    return this.setParam(key, this.get(key));
  }

  setParam(key: any, value: any) {
    this.params = this.p.set(key, value);
    return value;
  }

  field(name: string) {
    return this.form.controls[name];
  }

  set(name: string, value: any) {
    return this.field(name)?.setValue(value);
  }

  get(name: string, defaultValue: any = null) {
    //  const s = this.form.get(name);
    const f = this.field(name);
    if (!f || f === null) {
      // console.log(name + 'is null is it unexpected :WARN');
      return defaultValue;
    }

    const val = f.value;
    //  console.log(name, val);
    if (val !== undefined && val !== null)
      return val;
    //    if (!(val) || val === null) {
    return defaultValue;
    //    }
    //    return val;
  }

  reset() {
    // console.log('RESET');
    //    Object.keys(this.form.controls).forEach((name) => {
    //      this.form.controls[name].setErrors(null);
    //    });
    //    this.form.markAsPristine();
    //    this.form.markAsUntouched();
    //    this.form.updateValueAndValidity();
    this.id = null;
    this.form.reset();
    this.formData = null;
    //    this.state = 'new';
    //    this.islist = false;
  }

  clearParams() {
    if (this.params) {
      this.params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    }
  }

  equals(name: string, value: string) {
    const v = this.get(name);
    if (v !== undefined && v !== null) {
      return (('' + v) === value);
    }
    return false;
  }

  isEmpty(name: string) {
    const v = this.get(name);
    if (v !== undefined && v !== null) {
      return (('' + v).trim().length === 0);
    }
    return true;
  }

  isEmptyList(list = this.list) {
    if (list) {
      if (list instanceof Array) {
        return (list.length === 0)
      }
    }
    return true;
  }


  // Validation methods
  errors(name: string, f: any = null) {
    if (this.sysService.submitted) {
      const f1 = !f ? this.field(name) : f;
      // if (!f1)
      //  console.log(name + 'Not found');
      return (f1?.errors !== null);
    }
    else {
      return false;
    }
  }
  required(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.required : false;
  }
  email(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.email : false;
  }
  pattern(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.pattern : false;
  }
  minlength(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.minlength : false;
  }
  maxlength(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.maxlength : false;
  }
  compose(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.compose : false;

  }
  match(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.match : false;
  }

  hasError(name: string) {
    return this.sysService.submitted ? this.form.hasError(name) : false;
  }

  isList() {
    return this.islist;
  }

  isForm() {
    return this.isform;
  }

  isNew() {
    return this.state === 'new';
  }

  isEdit() {
    return this.state === 'edit';
  }

  isView() {
    return this.state === 'view';
  }
  isHidden() {
    return this.state === 'hide';
  }
  /**
  Set data on edit to existing form, will not reset previous form data
  */
  setToNextEdit(data: any, form: any = this.form, reset: boolean = false) {
    this._fill(data, form, reset);
    return this;
  }

  /**
   Set data on edit to existing form, will not reset previous form data
   */
  updateFormView(data: any, form: any = this.form, reset: boolean = false) {
    this._fill(data, form, reset, -1);
    return this;
  }

  setToForm(data: any, form: any = this.form, reset: boolean = false) {
    this._fill(data, form, reset, 0);
    return this;
  }


  find(field: string, value: any) {
    let i = 0;
    // console.log(value, this.list);
    for (const item of this.list) {
      if (item[field] === value) { //always id should be there
        return new Item(i, item);
      }
      i++;
    }
    return new Item(i, null);
  }
  /**
   Set data to list on save.
  */
  setFormToList(field: string, value: any = null, data: any = this.form.value) {
    if (value) {
      let item: Item = this.find(field, value);
      item.value = data;
      this.setToList(item);
    } else {
      if (this.list instanceof Array) {
        this.list.push(data);
      }
    }
    return this;
  }

  setToList(item: Item) {
    if (this.list instanceof Array) {
      this.list.splice(item.index, 1, item.value);
    }
  }

  addFormArray(name: string, arrayFormGroup: FormGroup, mainFrm: any = this.form) {
    let a = mainFrm.controls[name] as FormArray;
    a.push(arrayFormGroup);
  }

  delFormArray(name: string, index: number, mainFrm: any = this.form) {
    let a = mainFrm.controls[name] as FormArray;
    a.removeAt(index);
  }

  private setFormArray(name: string, values: any[], form: any = this.form) {
    const formArray = new FormArray([]);
    if (values) {
      let i: number = 0;
      values.forEach(v => {
        if (form && form.controls[name] && form.controls[name].controls) { //&& !this.isEmptyList(form.controls[name].controls[0])
          let frm = _.cloneDeep(form.controls[name].controls[0]);
          if (frm) {
            this._fill(v, frm, false, i++);
            formArray.push(frm);
          }
        }
      });
      if (i > 0)
        form.setControl(name, formArray);
    } else {
      if (form && form.controls[name] && form.controls[name].controls) { //&& !this.isEmptyList(form.controls[name].controls[0])
        let frm = _.cloneDeep(form.controls[name].controls[0]);
        if (frm) {
          this._fill(null, frm, false, 0);
          formArray.push(frm);
        }
      }
      //  console.log(formArray);
      form.setControl(name, formArray);
    }

  }

  private _fill(row: any, frm: any, reset: boolean = false, index: number = -1) {
    if (index === -1) this.view = row;
    if (row) {
      if (reset) {
        this.reset();
      }
      //     console.log(row);  
      let val: any;
      for (const item in frm.controls) {
        if (item) {
          val = row[item];
          if (val) {
            this._setFill(val, item, frm, reset, index++);
          }
          else if (val === null) {
            frm.controls[item].setValue(null);
          } else if (val !== undefined) {
            this._setFill(val, item, frm, reset, index++);
          }
        }
      }
      //  console.log(frm.value);
    }
  }

  private _setFill(val: any, item: any, frm: any, reset: boolean, index: number) {
    if (val instanceof FormGroup) {
      this._fill(val, frm.controls[item], reset, index++);
    }
    else if (val instanceof Array) {
      if (index > -1)
        this.setFormArray(item, val, frm);
      else
        this.setFormArray(item, val, frm);
      //this._fill(val, frm.controls[item], reset);
    }
    else
      frm.controls[item].setValue(val);
  }

  setParamFromModel(model: Model) {
    this.clearParams();
    let val: any;
    // console.log(model.form.value);
    for (const item in model.form.controls) {
      //console.log('Z'+item+'Z');
      if (item) {
        val = model.form.controls[item].value;

        if (val) {
          this._setParam(item, val);
        }
        else if (val === null) {
          //ignore
        } else if (val !== undefined) {
          this._setParam(item, val);
        }
      }
    }
  }

  private _setParam(item: string, val: any) {
    val = ('' + val).trim();
    if (val.length != 0) {
      this.setParam(item, val);
    }
  }

  get invalid() {
    return this.form.invalid;
  }
  get valid() {
    return this.form.valid;
  }

  setDefult(index: number, value: any) {
    this.formDefault.values[index] = value;
  }

  addDefault(field: string, value: any) {
    this.formDefault.fields.push(field);
    this.formDefault.values.push(value);
  }

  value() {
    return this.form.value;
  }

  getFormData() {
    return this.formData;
  }
  _getFormData() {
    if (!this.formData) this.formData = new FormData();
    return this.formData;
  }

  setFile(files: FileList | null, key: string = "file") {
    if (files) {
      let file = files.item(0);
      if (file)
        this._getFormData().set(key, file, file.name);
    } else
      this._getFormData().set(key, '', '');
  }
}

export class FormDefault {
  fields: any;
  values: any;
  constructor(fields: any = [], values: any = []) {
    this.fields = fields;
    this.values = values;
  }
}

export class Item {
  value: any;
  index: any;
  constructor(index: number, value: any) {
    this.index = index;
    this.value = value;
  }
  set(field, val) {
    if (this.value)
      this.value[field] = val;
  }
}