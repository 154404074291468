import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SysService, View, ViewFactory } from '@app/_common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-fpl-calculator',
  templateUrl: './fpl-calculator.component.html',
  styleUrls: ['./fpl-calculator.component.scss']
})
export class FplCalculatorComponent implements OnInit {
  private fplView: View = this.viewFactory.newView();
  form: FormGroup;
  calculatedPercentOfFpl: any = 0;
  POVERTY_THRESHOLD: number = 0;
  THRESHOLD_EXTRA: number = 0;
  monthly: any;
  biweekly: any;
  semiMonthly:any;
  weekly: any;
  annually: any;
  annualFPL: any;
  monthlyFPL: any = 0;
  year: any = new Date().getFullYear();
  fplDataRecords: any;
  currentYearRecord: any=[];
  resultData: any=[];
  selectedYear: any;

  constructor(private _formBuilder: FormBuilder,public ref: DynamicDialogRef,public config: DynamicDialogConfig,
    public sysService: SysService, private viewFactory: ViewFactory) {

     }

  ngOnInit(): void {
    this.getFPLthresholdData(this.config.data);
    this.form = this._formBuilder.group({
      year: [],
      familySize: [1],
      annually: [],
      monthly: [],
      biweekly: [],
      semiMonthly:[],
      weekly: [],
      percentOfFPL: [],
      percentage: []
    });
  }

  calculatePercentOfFPL() {
    this.calculatedPercentOfFpl = ((this.form.value.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
    this.monthly = (this.form.value.annually / 12).toFixed(2);
    this.biweekly = (this.form.value.annually / 26).toFixed(2);
    this.semiMonthly = (this.form.value.annually / 24).toFixed(2);
    this.weekly = (this.form.value.annually / 52).toFixed(2);
  }

  changeMonthlyValue() {
    this.annually = (this.form.value.monthly * 12).toFixed(2);
    this.biweekly = (this.annually / 26).toFixed(2);
    this.semiMonthly = (this.annually / 24).toFixed(2);
    this.weekly = (this.annually / 52).toFixed(2);
    this.calculatedPercentOfFpl = ((this.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
  }

  changesemiMonthlyValue() {
    this.annually = (this.form.value.semiMonthly * 24).toFixed(2);
    this.weekly = (this.annually / 52).toFixed(2);
    this.biweekly = (this.weekly * 2).toFixed(2);
    // this.semiMonthly = (this.annually / 104).toFixed(2);
    this.monthly = (this.annually / 12).toFixed(2);
    this.calculatedPercentOfFpl = ((this.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
  }

  changeBiweeklyValue() {
    this.weekly = (this.form.value.biweekly / 2).toFixed(2);
    this.annually = (this.form.value.biweekly * 26).toFixed(2);
    this.monthly = (this.annually / 12).toFixed(2);
    this.semiMonthly = (this.annually / 24 ).toFixed(2);
    this.calculatedPercentOfFpl = ((this.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
  }

  changeWeeklyValue() {
    this.biweekly = (this.form.value.weekly * 2).toFixed(2);
    this.annually = (this.form.value.weekly * 52).toFixed(2);
    this.semiMonthly = (this.annually / 24).toFixed(2);
    this.monthly = (this.annually / 12).toFixed(2);
    this.calculatedPercentOfFpl = ((this.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
  }

  // calculateAnnualValue(){

  // }

  //  This method should be called for reverse calculation. If we enter % of FPL and need to calculate annually, monthly, biweekly and weekly income.
  // changePercentOfFPL() {
  //   this.annually = ((this.form.value.percentOfFPL * (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) / 100).toFixed(2);
  //   this.monthly = (this.annually / 12).toFixed(2);
  //   this.biweekly = (this.annually / 24).toFixed(2);
  //   this.weekly = (this.annually / 52).toFixed(2);
  // }

  calculateMonthlyFPL() {
    this.annualFPL = (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA)).toFixed(2);
    this.monthlyFPL = (((this.annualFPL / 12) * this.form.value.percentage) / 100).toFixed(2);
  }

  selectYear(fplItem) {
    const fieldsToReset = {
      familySize: 1,
      annually: "",
      monthly: "",
      biweekly: "",
      semiMonthly:"",
      weekly: "",
      percentOfFPL: "",
      percentage: ""
    }
    this.form.patchValue(fieldsToReset)
    this.calculatedPercentOfFpl = 0;
    this.monthlyFPL = 0;
    this.POVERTY_THRESHOLD = fplItem.poverty_threshold;
    this.THRESHOLD_EXTRA = fplItem.threshold;
  }


  flager:string;
  getFPLthresholdData(data) {
    this.selectedYear = data?.year;
    this.resultData = data;
    if(this.resultData != ""){
      this.flager = "Yes"
      this.currentYearRecord = data;
      this.fplDataRecords = [data]; 
      this.POVERTY_THRESHOLD = this.fplDataRecords[0]?.poverty_threshold;
      this.THRESHOLD_EXTRA = this.fplDataRecords[0]?.threshold;
    }
    else{
      this.flager = "No"
    }
  }

  

}

