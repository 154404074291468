import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { StaticService } from './data/static.service';
import { FieldErrorComponent } from './field-error/field-error.component';
import { TableExportComponent } from './table-export/table-export.component';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppHeaderComponent } from './header/header.component';
import { AvatarModule } from 'primeng/avatar';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToastModule } from 'primeng/toast';
import { CustomPopupComponent } from '@app/custom-popup/custom-popup.component';
import { LoaderFetchComponent } from './loader/loader-fetch.component';


@NgModule({
  imports: [CommonModule,ConfirmDialogModule, ButtonModule, DropdownModule, AvatarModule, FormsModule, MenuModule, OverlayPanelModule, DialogModule, ReactiveFormsModule, MessagesModule, BreadcrumbModule, ToastModule],
  declarations: [AppHeaderComponent, FieldErrorComponent, TableExportComponent,CustomPopupComponent,LoaderFetchComponent],
  exports: [AppHeaderComponent, FieldErrorComponent, TableExportComponent,LoaderFetchComponent,
    CommonModule, FormsModule, DialogModule, ConfirmDialogModule, BreadcrumbModule, ToastModule],
  providers: [StaticService,LoaderFetchComponent],
})
export class SharedModule { }
