import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormDefault, Model, SysService, View, ViewFactory } from '@app/_common';
import { AppConstants } from '@app/_config';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.css']
})
export class CustomPopupComponent implements OnInit {

  
  constructor(
    private router: Router,
    private viewFactory: ViewFactory,
    public sysService: SysService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {

  }



}
