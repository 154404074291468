
export const AppConstants = {
    tokenKey: 'auth-token', //key name to store token
    userKey: 'auth-user', //loggedin user details key

    confirm: 'Confirm',

    saveSuccess: 'Saved Succesfully',
    deleteSuccess: 'Deleted Succesfully',
    deleteConfirm: 'Are you sure want to delete ?',

    saveFailed: 'Failed to save.',
    deleteFailed: 'Failed to delete.',
    selectFailed: 'Unable to select',
    validationFailed: 'Looks like you have not provided all the required information. Please check and try again.',

    noRecordFound: 'No records found.',

    // regexPassword:'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}',
    regexPassword: '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}',
    regexEmail: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$',
    regexZipCpde: '(^[0-9]{5}(-[0-9]{4})?\$)',

    err400: 'System configuration error.',  //400 error message     
    err401: 'User name or password is invalid.',  //401 error message
    err403: 'You are not authorized.',  //403 error message
    err500: 'Something went wrong. Please try after sometime.', //500 error message
    errUnknow: 'Something went wrong.' //Unhandled error message
};

