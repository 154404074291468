import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { FormDefault, Model } from '../components/model.view';
import { View } from '../components/view.component';
import { ViewFactory } from '../components/view.factory.component';
import { ApiService } from '../services/api.service';
import { SysService } from '../services/sys.service';
import { UserService } from '../services/user.service';
import { MessageService } from 'primeng/api';
import { environment } from '@environments/environment';
import { timer } from 'rxjs';
import { AppConstants } from '@app/_config';
import { DialogService } from 'primeng/dynamicdialog';
import { FplCalculatorComponent } from '@app/calendar/fpl-calculator/fpl-calculator.component';

type AppTheme = {
  theme: string;
  logo: string;
};

@Component({
  selector: 'f-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  menus: MenuItem[];
  sideNav: Element | undefined;
  wrapper: Element | undefined;
  @ViewChildren('myId') innerDiv1: ElementRef;
  @ViewChild('days', { static: true }) days: ElementRef;
  @ViewChild('hours', { static: true }) hours: ElementRef;
  @ViewChild('minutes', { static: true }) minutes: ElementRef;
  @ViewChild('seconds', { static: true }) seconds: ElementRef;
  url: any;
  apiUrl: string = environment.appApi;
  assignedToList: any = [];
  FileTypeList: any = [];
  fileToUpload: File;
  timerFlag = false;
  timerRun: any;
  private headView: View = this.viewFactory.newView();

  private themes: {
    [x: string]: any;
    www: AppTheme;
    tcdirect: AppTheme;
    bc: AppTheme;
    c2g: AppTheme;
    primepay: AppTheme;
  } = {
      www: { theme: 'theme-blue', logo: 'logo.png' },
      tcdirect: { theme: 'theme-gray', logo: 'TCLogo.jpg' },
      bc: { theme: 'theme-default', logo: 'logo.png' },
      c2g: { theme: 'theme-green', logo: 'c2g90.png' },
      primepay: { theme: 'theme-blue', logo: 'NBPlogo.png' }
    };
  currentYearRecord: any;
  dynamicDialogRef: import("primeng/dynamicdialog").DynamicDialogRef;
  year: number;
  form: any;

  constructor(
    private route: ActivatedRoute,
    public sysService: SysService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private viewFactory: ViewFactory,
    public messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialogService: DialogService,
    private router: Router) {
      this.form = this.formBuilder.group({
        year: new FormControl('') // Initialize the year form control with an empty value
        // ... other form controls ...
      });
  

  }

  ngOnInit(): void {
    this.url = this.apiUrl;
    this.headView.of(this.headModel(), false);
    this.menus = [
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => {
          this.logout();
        }
      }
    ];

  }



  logout(): void {
    localStorage.setItem("eventTrigger", "false");
    this.headView.toSave(`/auth/logout`, '', '').then(data => {
      if (data.success == true) {
        window.localStorage.clear();
        // this.sysService.appRefresh();
        this.router.navigate([`/`]);
        window.location.reload();



      }
    })
  }

  get profile() {
    return this.headView.model;

  }
  getTimer() {


    return this.sysService?.timerRun;
  }
  getTimerFlag() {
    if (localStorage.getItem('isTimer') === 'true') {
      return true;
    }
    else {
      return false;
    }
  }
  getCallFlag() {
    if (localStorage.getItem('isOnCall') === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  toggleMenu(): void {
    this.sideNav = document.querySelectorAll('.sideNav')[0];
    this.wrapper = document.querySelectorAll('#wrapper')[0];
    if (this.sideNav === undefined || this.wrapper === undefined) {
      return;
    }
    if (this.sideNav.classList.contains('opened')) {
      this.sideNav.classList.remove('opened');
      this.wrapper.classList.remove('menuOpened');
    } else {
      this.sideNav.classList.add('opened');
      this.wrapper.classList.add('menuOpened');
    }

  }
  private headModel(): Model {
    const formDefault = new FormDefault();
    return new Model(this.formBuilder.group({
      FileID: []
    }), this.sysService, formDefault, '');

  }
  onChange(event): void {
    this.headView.setParam('sessioncontext', event.value);
    this.sysService.siteContext = event.value;
    this.headView.getData('/site/change').then(data => {
      const sel = this.sysService.siteList.filter(d => d.OwnerPartyID === this.sysService.siteContext);
      this.userService.changeSite(data, this.route, this.sysService);
      if (!this.headView.isEmptyList(sel)) {
        const site: any = this.themes[sel[0]?.subDomain?.toLowerCase()];
        if (site) {
          this.sysService.changeTheme(site?.theme, site?.logo);
        }
      }
    });

  }
  selectFile(files: FileList) {
    this.profile.setFile(files, "UserFile");

  }

  async profilePicture() {
    let data = await this.headView.toSaveList('/profile', "")
    if (data) {
      this.sysService.getUser().ProfilePic = data.ProfilePic;
    }

  }
  navAgents() {
    this.router.navigate([`/agent`]);

  }
  navResources() {
    this.router.navigate([`/resources`]);

  }


  findLatestPastYearRecord(data: any[], currentYear: number): any {
    let latestPastYearRecord = null;
  data.forEach(item => {
    this.year = parseInt(item.year, 10);
    if (this.year < currentYear && (!latestPastYearRecord || this.year > parseInt(latestPastYearRecord[this.year], 10))) {
      latestPastYearRecord = item;
    }
  });
  return latestPastYearRecord;
  }

  
  flager:string;
  navFplCalculator() {
   const currentYear = new Date().getFullYear();
   this.headView.getData("/fpl-calculator/last-record").then((data: any[]) => { 
    const apiResponse: any[] = data; 
     this.currentYearRecord = data.find(item => item?.year === currentYear.toString());
     if(this.currentYearRecord == undefined) {
      this.currentYearRecord = this.findLatestPastYearRecord(apiResponse, currentYear);
      if (this.currentYearRecord === null) {
        // If there are no past year records or the past year is in the future, set data to empty string
        this.flager = "No"; // Set data to empty string
        const data = "";
        this.dynamicDialogRef = this.dialogService.open(FplCalculatorComponent, {
          header: 'FPL calculator',
          width: '30%',
          data:data,
        });
      } 
      else {
       const data = "";
       this.dynamicDialogRef = this.dialogService.open(FplCalculatorComponent, {
         header: 'FPL calculator',
         width: '30%',
         data: this.currentYearRecord,
       });
      }
     }
     else if(this.currentYearRecord.year){    
       const data = this.currentYearRecord;
    this.dynamicDialogRef = this.dialogService.open(FplCalculatorComponent, {
         header: 'FPL calculator',
         width: '30%',
         data: data,
       });
     }
     else{
       const data = "";
       this.dynamicDialogRef = this.dialogService.open(FplCalculatorComponent, {
         header: 'FPL Calculator',
         width: '30%',
         data: data,
       });
     }
     this.form.get('year').setValue(this.currentYearRecord?.year || this.findLatestPastYearRecord(apiResponse, currentYear)?.year);
    });
  }


}
