export const environment = {
  production: false,
  appApi: 'https://aliisapi-uat.legalservicesnyc.org',
  logApi: 'https://aliisapi-uat.legalservicesnyc.org/logs/error',
  backend: { spring: false, php: true, node: false }, //first true will be applied make spring false and then make node true to use node app
  auth: {
    login: 'https://aliisapi-uat.legalservicesnyc.org/auth/login',  //Node js '/signin/' // Spring Boot build in '/oauth/token/'
    logout: 'https://aliisapi-uat.legalservicesnyc.org/auth/logout',
    register: 'https://aliisapi-uat.legalservicesnyc.org/auth/register',
    clientId: '',  //Required for Spring boot, left empty for node js php
    clientSecret: ''  //Required for Spring boot, left empty for node js php
  }
};