export class StaticData {

  static Status = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];

  static confirmStatus = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  static is_not_lsnyc_issue = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ];

  static is_proxy_options = [
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ]

  static role_id = [
    { label: 'Admin', value: 1 },
    { label: 'Agent', value: 2 },
    { label: 'Office Staff', value: 3 },
  ];

  static callType = [
    { label: 'Inbound', value: 'inbound' },
    { label: 'Outbound', value: 'Outbound' },
    { label: 'Callback', value: 'Callback' },
    { label: 'Manual', value: 'Manual' }
  ];

  static VeteranStatus = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' },
    { label: 'Unknown', value: 'Unknown' }
  ];

  static callerType = [
    { label: 'Individual', value: 'Individual' },
    { label: 'Organization', value: 'Organization' },
    { label: 'Unknown', value: 'Unknown' }
  ];
  static IsProxy = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' }
  ];
  static LegalProxyVerification = [
    { label: "Consent", value: "Consent" },
    { label: "Guardianship", value: "Guardianship" },
    { label: "Healthcare Proxy", value: "Healthcare Proxy" },
    { label: "Not Applicable", value: "Not Applicable" },
    { label: "Power of Attorney", value: "Power of Attorney" },
    { label: "Parent of a Minor Child", value: "Parent of a Minor Child" },
    {
      label: "Representative payee for SSN / SSI / SSD benefits",
      value: "Representative payee for SSN / SSI / SSD benefits",
    },
  ];
  static ProvideProxy = [
    { label: "Provide Now", value: "now" },
    { label: "Provide Later", value: "later" },
    { label: "Do not want to provide", value: "dont_provide" },
  ];
  static LegalProxyRelatioship = [
    { label: "Friend", value: "Friend" },
    { label: "Relative", value: "Relative" },
    { label: "Social Worker", value: "Social worker" },
    { label: "Other", value: "Other" },
  ];

  static IssueDispositionStatus = [
    { label: 'Incomplete', value: 'incomplete' },
    { label: 'Intake', value: 'intake' },
    { label: 'No Intake', value: 'no_intake' }
  ];
  static IssueProfileType = [
    { label: 'Group', value: 'group' },
    { label: 'Individual', value: 'individual' },
  ];
  static tableSearchFields = [
    { label: 'First Name', value: 'first_name' },
    { label: 'Last Name', value: 'last_name' },
    { label: 'Phone Number', value: 'phone' }
  ];

  static productStatus = [
    { label: 'INSTOCK', value: 'instock' },
    { label: 'LOWSTOCK', value: 'lowstock' },
    { label: 'OUTOFSTOCK', value: 'outofstock' }
  ];
  static officeLocStatus = [
    { label: 'Active', value: 'active' },
    { label: 'In-active', value: 'inactive' },
  ];
  static activeStatus = [
    { label: 'Inactive', value: 0 },
    { label: 'Active', value: 1 },
  ];
  static Permission = [
    { label: 'Manage', value: '' },
    { label: 'No Access', value: '' },
    { label: 'View', value: '' },
  ];
  static NoPermission = [
    { label: 'No Access', value: '' },
    { label: 'View', value: '' },
  ];
  static piedmontConnectAutocomplete = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static ConservationBiller = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static PrimaryLanguage = [
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Other', value: 'Other' },
  ];
  static Complaint = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static frozen = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static agentViewable = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static contactByEmail = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static contactBySms = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static merchantId = [
    { label: 'Piedmont (166068)', value: 1 },
    { label: 'Colonial (190879)', value: 2 }
  ];
  static inactiveReason = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Suspended', value: 'Suspended' }
  ];
  static expenseMemo = [
    { label: 'Refund', value: 'Refund' },
    { label: 'Reactivation', value: 'Reactivation' },
  ]
  static fundingMethod = [
    { label: 'Draft Bank Account', value: 'Draft Bank Account' },
    { label: 'Payment Card', value: 'Payment Card' },
    { label: 'Direct Deposit', value: 'Direct Deposit' },
  ]

  static search_by = [
    { label: 'Name', value: 'Name' },
    { label: 'Phone Number', value: 'Phone Number' },
  ]

  static pronoun = [
    { label: 'He/Him/His', value: 'He/Him/His' },
    { label: 'She/Her/Hers', value: 'She/Her/Hers' },
    { label: 'They/Them/Theirs', value: 'They/Them/Theirs' },
    { label: 'Another pronoun', value: 'Another pronoun' },
    { label: 'Unknown', value: 'Unknown' },
  ]
  // static biller = [
  //   { label: 'all state', value: '1' }
  // ];
  // static PiedmontSalesPerson = [
  //   { label: 'Piedmont Payment Serivce', value: 'Piedmont Payment Serivce' },
  //   { label: 'Naumam , Michael', value: 'Naumam , Michael' },
  //   { label: 'Ginter,Matthew ', value: 'Ginter,Matthew' },
  //   { label: 'Augustine,Jami ', value: 'Augustine ,Jami' },

  // ];



  static Country = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Aland Islands", value: "Aland Islands" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "American Samoa", value: "American Samoa" },
    { label: "AndorrA", value: "AndorrA" },
    { label: "Angola", value: "Angola" },
    { label: "Anguilla", value: "Anguilla" },
    { label: "Antarctica", value: "Antarctica" },
    { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Aruba", value: "Aruba" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bermuda", value: "Bermuda" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Bouvet Island", value: "Bouvet Island" },
    { label: "Brazil", value: "Brazil" },
    {
      label: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
    },
    { label: "Brunei Darussalam", value: "Brunei Darussalam" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Cape Verde", value: "Cape Verde" },
    { label: "Cayman Islands", value: "Cayman Islands" },
    { label: "Central African Republic", value: "Central African Republic" },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Christmas Island", value: "Christmas Island" },
    { label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    { label: "Congo", value: "Congo" },
    {
      label: "Congo, The Democratic Republic of the",
      value: "Congo, The Democratic Republic of the",
    },
    { label: "Cook Islands", value: "Cook Islands" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Cote D'Ivoire", value: "Cote D'Ivoire" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czech Republic", value: "Czech Republic" },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    { label: "Ethiopia", value: "Ethiopia" },
    {
      label: "Falkland Islands (Malvinas)",
      value: "Falkland Islands (Malvinas)",
    },
    { label: "Faroe Islands", value: "Faroe Islands" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "French Guiana", value: "French Guiana" },
    { label: "French Polynesia", value: "French Polynesia" },
    {
      label: "French Southern Territories",
      value: "French Southern Territories",
    },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Gibraltar", value: "Gibraltar" },
    { label: "Greece", value: "Greece" },
    { label: "Greenland", value: "Greenland" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guadeloupe", value: "Guadeloupe" },
    { label: "Guam", value: "Guam" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guernsey", value: "Guernsey" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    {
      label: "Heard Island and Mcdonald Islands",
      value: "Heard Island and Mcdonald Islands",
    },
    {
      label: "Holy See (Vatican City State)",
      value: "Holy See (Vatican City State)",
    },
    { label: "Honduras", value: "Honduras" },
    { label: "Hong Kong", value: "Hong Kong" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran, Islamic Republic Of", value: "Iran, Islamic Republic Of" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Isle of Man", value: "Isle of Man" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jersey", value: "Jersey" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    {
      label: "Korea, Democratic People'S Republic of",
      value: "Korea, Democratic People'S Republic of",
    },
    { label: "Korea, Republic of", value: "Korea, Republic of" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    {
      label: "Lao People'S Democratic Republic",
      value: "Lao People'S Democratic Republic",
    },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Macao", value: "Macao" },
    {
      label: "Macedonia, The Former Yugoslav Republic of",
      value: "Macedonia, The Former Yugoslav Republic of",
    },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Marshall Islands", value: "Marshall Islands" },
    { label: "Martinique", value: "Martinique" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mayotte", value: "Mayotte" },
    { label: "Mexico", value: "Mexico" },
    {
      label: "Micronesia, Federated States of",
      value: "Micronesia, Federated States of",
    },
    { label: "Moldova, Republic of", value: "Moldova, Republic of" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montserrat", value: "Montserrat" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Myanmar", value: "Myanmar" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    { label: "Netherlands Antilles", value: "Netherlands Antilles" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "Niue", value: "Niue" },
    { label: "Norfolk Island", value: "Norfolk Island" },
    { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
    { label: "Norway", value: "Norway" },
    { label: "Oman", value: "Oman" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    {
      label: "Palestinian Territory, Occupied",
      value: "Palestinian Territory, Occupied",
    },
    { label: "Panama", value: "Panama" },
    { label: "Papua New Guinea", value: "Papua New Guinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Pitcairn", value: "Pitcairn" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },
    { label: "Puerto Rico", value: "Puerto Rico" },
    { label: "Qatar", value: "Qatar" },
    { label: "Reunion", value: "Reunion" },
    { label: "Romania", value: "Romania" },
    { label: "Russian Federation", value: "Russian Federation" },
    { label: "RWANDA", value: "RWANDA" },
    { label: "Saint Helena", value: "Saint Helena" },
    { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { label: "Saint Lucia", value: "Saint Lucia" },
    { label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
    {
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia and Montenegro", value: "Serbia and Montenegro" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    { label: "Solomon Islands", value: "Solomon Islands" },
    { label: "Somalia", value: "Somalia" },
    { label: "South Africa", value: "South Africa" },
    {
      label: "South Georgia and the South Sandwich Islands",
      value: "South Georgia and the South Sandwich Islands",
    },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Sudan", value: "Sudan" },
    { label: "Surilabel", value: "Surilabel" },
    { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
    { label: "Swaziland", value: "Swaziland" },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Syrian Arab Republic", value: "Syrian Arab Republic" },
    { label: "Taiwan, Province of China", value: "Taiwan, Province of China" },
    { label: "Tajikistan", value: "Tajikistan" },
    {
      label: "Tanzania, United Republic of",
      value: "Tanzania, United Republic of",
    },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor-Leste", value: "Timor-Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tokelau", value: "Tokelau" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    { label: "Turks and Caicos Islands", value: "TTurks and Caicos IslandsC" },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "United Arab Emirates", value: "United Arab Emirates" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "United States", value: "United States" },
    {
      label: "United States Minor Outlying Islands",
      value: "United States Minor Outlying Islands",
    },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Viet Nam", value: "Viet Nam" },
    { label: "Virgin Islands, British", value: "Virgin Islands, British" },
    { label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
    { label: "Wallis and Futuna", value: "Wallis and Futuna" },
    { label: "Western Sahara", value: "Western Sahara" },
    { label: "Yemen", value: "Yemen" },
    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" },
  ];


  static State = [
    { "label": "AK-Alaska", "value": "AK" },
    { "label": "AL-Alabama", "value": "AL" },
    { "label": "AR-Arkansas", "value": "AR" },
    { "label": "AZ-Arizona", "value": "AZ" },
    { "label": "CA-California", "value": "CA" },
    { "label": "CO-Colorado", "value": "CO" },
    { "label": "CT-Connecticut", "value": "CT" },
    { "label": "DC-Dist. of Columbia", "value": "DC" },
    { "label": "DE-Delaware", "value": "DE" },
    { "label": "FL-Florida", "value": "FL" },
    { "label": "GA-Georgia", "value": "GA" },
    { "label": "GU-Guam", "value": "GU" },
    { "label": "HI-Hawaii", "value": "HI" },
    { "label": "IA-Iowa", "value": "IA" },
    { "label": "ID-Idaho", "value": "ID" },
    { "label": "IL-Illinois", "value": "IL" },
    { "label": "IN-Indiana", "value": "IN" },
    { "label": "KS-Kansas", "value": "KS" },
    { "label": "KY-Kentucky", "value": "KY" },
    { "label": "LA-Louisiana", "value": "LA" },
    { "label": "MA-Massachusetts", "value": "MA" },
    { "label": "MD-Maryland", "value": "MD" },
    { "label": "ME-Maine", "value": "ME" },
    { "label": "MI-Michigan", "value": "MI" },
    { "label": "MN-Minnesota", "value": "MN" },
    { "label": "MO-Missouri", "value": "MO" },
    { "label": "MS-Mississippi", "value": "MS" },
    { "label": "MT-Montana", "value": "MT" },
    { "label": "NC-North Carolina", "value": "NC" },
    { "label": "ND-North Dakota", "value": "ND" },
    { "label": "NE-Nebraska", "value": "NE" },
    { "label": "NH-New Hampshire", "value": "NH" },
    { "label": "NJ-New Jersey", "value": "NJ" },
    { "label": "NM-New Mexico", "value": "NM" },
    { "label": "NV-Nevada", "value": "NV" },
    { "label": "NY-New York", "value": "NY" },
    { "label": "OH-Ohio", "value": "OH" },
    { "label": "OK-Oklahoma", "value": "OK" },
    { "label": "OR-Oregon", "value": "OR" },
    { "label": "PA-Pennsylvania", "value": "PA" },
    { "label": "PR-Puerto Rico", "value": "PR" },
    { "label": "RI-Rhode Island", "value": "RI" },
    { "label": "SC-South Carolina", "value": "SC" },
    { "label": "SD-South Dakota", "value": "SD" },
    { "label": "TN-Tennessee", "value": "TN" },
    { "label": "TX-Texas", "value": "TX" },
    { "label": "UT-Utah", "value": "UT" },
    { "label": "VA-Virginia", "value": "VA" },
    { "label": "VI-Virgin Islands", "value": "VI" },
    { "label": "VT-Vermont", "value": "VT" },
    { "label": "WA-Washington", "value": "WA" },
    { "label": "WI-Wisconsin", "value": "WI" },
    { "label": "WV-West Virginia", "value": "WV" },
    { "label": "WY-Wyoming", "value": "WY" }
  ];

  static agentFeeSplitBusinessAccount = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 }
  ];
  static agentFeeSplitAccountType = [
    { label: 'Checking Account', value: 'DDA' },
    { label: 'Savings Account', value: 'Savings' },
  ];
  static agentFeeSplitFederalTaxClassification = [
    { label: 'Individual/Sole Proprietor or Single Member LLC', value: 1 },
    { label: 'C Corporation', value: 2 },
    { label: 'S Corporation', value: 3 },
    { label: 'Partnership', value: 4 },
    { label: 'Trust/Estate', value: 5 },
    { label: 'Limited Liability Company (C Corporation)', value: 6 },
    { label: 'Limited Liability Company (S Corporation)', value: 7 },
    { label: 'Limited Liability Company (Partnership)', value: 8 },
  ];
}
